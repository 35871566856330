import {
  CompanyPlanGroup,
  CompanyPlanKey,
  CompanyProviderIncomePaymentMethod,
  CompanyProviderPaymentService,
} from '@/enums';
import type { CompanyPlanKeys } from '@/interfaces';

const MONTHLY_CARD_ISSUE_LIMIT_TITLE = 'Monthly virtual cards limit';
const ACTIVE_CARDS_LIMIT_TITLE = 'Active virtual cards limit';
const CARD_ISSUING_FEE_TITLE = 'Issue additional virtual card';

const INTERNATIONAL_PAYMENT_FEE_TITLE = 'Cross-border transaction';
const CURRENCY_EXCHANGE_FEE_TITLE = 'Foreign exchange';
const INBOUND_ACH_FEE_TITLE = 'ACH Incoming';
const OUTBOUND_ACH_FEE_TITLE = 'ACH Outgoing';
const INBOUND_WIRE_FEE_TITLE = 'Fedwire Incoming';
const OUTBOUND_WIRE_FEE_TITLE = 'Fedwire Outgoing';
const INBOUND_SWIFT_FEE_TITLE = 'SWIFT Incoming';

const INBOUND_CB_FEE_TITLE = 'Crypto deposit';

export const CompanyPlanKeysMap: CompanyPlanKeys = {
  // Stripe Treasury
  [CompanyPlanKey.StripeInboundAchFee]: {
    title: INBOUND_ACH_FEE_TITLE,
    group: CompanyPlanGroup.Treasury,
    order: 1,
    providerPaymentServices: [CompanyProviderPaymentService.Stripe],
  },
  [CompanyPlanKey.StripeOutboundAchFee]: {
    title: OUTBOUND_ACH_FEE_TITLE,
    group: CompanyPlanGroup.Treasury,
    order: 2,
    providerPaymentServices: [CompanyProviderPaymentService.Stripe],
  },
  [CompanyPlanKey.StripeInboundWireFee]: {
    title: INBOUND_WIRE_FEE_TITLE,
    group: CompanyPlanGroup.Treasury,
    order: 3,
    providerPaymentServices: [CompanyProviderPaymentService.Stripe],
  },
  [CompanyPlanKey.StripeOutboundWireFee]: {
    title: OUTBOUND_WIRE_FEE_TITLE,
    group: CompanyPlanGroup.Treasury,
    order: 4,
    providerPaymentServices: [CompanyProviderPaymentService.Stripe],
  },
  [CompanyPlanKey.StripeInboundCbFee]: {
    title: INBOUND_CB_FEE_TITLE,
    group: CompanyPlanGroup.Treasury,
    order: 5,
    providerPaymentServices: [CompanyProviderPaymentService.Stripe],
    incomePaymentMethods: [CompanyProviderIncomePaymentMethod.InboundCrypto],
  },

  // Stripe Cards
  [CompanyPlanKey.StripeCardIssuingFee]: {
    title: CARD_ISSUING_FEE_TITLE,
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Stripe],
    order: 6,
  },
  [CompanyPlanKey.StripeMonthlyCardIssueLimit]: {
    title: MONTHLY_CARD_ISSUE_LIMIT_TITLE,
    group: CompanyPlanGroup.Cards,
    order: 7,
    providerPaymentServices: [CompanyProviderPaymentService.Stripe],
  },
  [CompanyPlanKey.StripeActiveCardsLimit]: {
    title: ACTIVE_CARDS_LIMIT_TITLE,
    group: CompanyPlanGroup.Cards,
    order: 8,
    providerPaymentServices: [CompanyProviderPaymentService.Stripe],
  },
  [CompanyPlanKey.StripeInternationalPaymentFee]: {
    title: INTERNATIONAL_PAYMENT_FEE_TITLE,
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Stripe],
    order: 9,
  },
  [CompanyPlanKey.StripeCurrencyExchangeFee]: {
    title: CURRENCY_EXCHANGE_FEE_TITLE,
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Stripe],
    order: 10,
  },

  // Stripe Invoices
  [CompanyPlanKey.StripeInvoicePaidFee]: {
    title: 'Fee per paid invoice',
    group: CompanyPlanGroup.Invoices,
    order: 10,
    providerPaymentServices: [CompanyProviderPaymentService.Stripe],
  },

  // Airwallex Treasury
  [CompanyPlanKey.AirwallexInboundAchFee]: {
    title: INBOUND_ACH_FEE_TITLE,
    group: CompanyPlanGroup.Treasury,
    order: 1,
    providerPaymentServices: [CompanyProviderPaymentService.Airwallex],
  },
  [CompanyPlanKey.AirwallexInboundWireFee]: {
    title: INBOUND_WIRE_FEE_TITLE,
    group: CompanyPlanGroup.Treasury,
    order: 2,
    providerPaymentServices: [CompanyProviderPaymentService.Airwallex],
  },
  [CompanyPlanKey.AirwallexInboundSwiftFee]: {
    title: INBOUND_SWIFT_FEE_TITLE,
    group: CompanyPlanGroup.Treasury,
    order: 3,
    providerPaymentServices: [CompanyProviderPaymentService.Airwallex],
  },
  [CompanyPlanKey.AirwallexInboundCbFee]: {
    title: INBOUND_CB_FEE_TITLE,
    group: CompanyPlanGroup.Treasury,
    order: 4,
    providerPaymentServices: [CompanyProviderPaymentService.Airwallex],
    incomePaymentMethods: [CompanyProviderIncomePaymentMethod.InboundCrypto],
  },

  // Airwallex Cards
  [CompanyPlanKey.AirwallexCardIssuingFee]: {
    title: CARD_ISSUING_FEE_TITLE,
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Airwallex],
    order: 5,
  },
  [CompanyPlanKey.AirwallexMonthlyCardIssueLimit]: {
    title: MONTHLY_CARD_ISSUE_LIMIT_TITLE,
    group: CompanyPlanGroup.Cards,
    order: 6,
    providerPaymentServices: [CompanyProviderPaymentService.Airwallex],
  },
  [CompanyPlanKey.AirwallexActiveCardsLimit]: {
    title: ACTIVE_CARDS_LIMIT_TITLE,
    group: CompanyPlanGroup.Cards,
    order: 7,
    providerPaymentServices: [CompanyProviderPaymentService.Airwallex],
  },
  [CompanyPlanKey.AirwallexInternationalPaymentFee]: {
    title: INTERNATIONAL_PAYMENT_FEE_TITLE,
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Airwallex],
    order: 8,
  },
  [CompanyPlanKey.AirwallexCurrencyExchangeFee]: {
    title: CURRENCY_EXCHANGE_FEE_TITLE,
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Airwallex],
    order: 9,
  },
  [CompanyPlanKey.AirwallexSmallDomesticFee]: {
    title: 'Domestic transactions under $10.00',
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Airwallex],
    order: 10,
  },
  [CompanyPlanKey.AirwallexSmallInternationalFee]: {
    title: 'International transactions under $30.00',
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Airwallex],
    order: 11,
  },

  // Marqeta Treasury
  [CompanyPlanKey.MarqetaInboundCbFee]: {
    title: INBOUND_CB_FEE_TITLE,
    group: CompanyPlanGroup.Treasury,
    order: 1,
    providerPaymentServices: [CompanyProviderPaymentService.Marqeta],
    incomePaymentMethods: [CompanyProviderIncomePaymentMethod.InboundCrypto],
  },

  // Marqeta Cards
  [CompanyPlanKey.MarqetaCardIssuingFee]: {
    title: CARD_ISSUING_FEE_TITLE,
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Marqeta],
    order: 2,
  },
  [CompanyPlanKey.MarqetaMonthlyCardIssueLimit]: {
    title: MONTHLY_CARD_ISSUE_LIMIT_TITLE,
    group: CompanyPlanGroup.Cards,
    order: 3,
    providerPaymentServices: [CompanyProviderPaymentService.Marqeta],
  },
  [CompanyPlanKey.MarqetaActiveCardsLimit]: {
    title: ACTIVE_CARDS_LIMIT_TITLE,
    group: CompanyPlanGroup.Cards,
    order: 4,
    providerPaymentServices: [CompanyProviderPaymentService.Marqeta],
  },
  [CompanyPlanKey.MarqetaInternationalPaymentFee]: {
    title: 'Cross-border fee',
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Marqeta],
    order: 5,
  },
  [CompanyPlanKey.MarqetaCurrencyExchangeFee]: {
    title: CURRENCY_EXCHANGE_FEE_TITLE,
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Marqeta],
    order: 6,
  },

  // Amaiz Treasury
  [CompanyPlanKey.AmaizEurInboundCbFee]: {
    title: INBOUND_CB_FEE_TITLE,
    group: CompanyPlanGroup.Treasury,
    order: 1,
    providerPaymentServices: [CompanyProviderPaymentService.Amaiz],
    incomePaymentMethods: [CompanyProviderIncomePaymentMethod.InboundCrypto],
  },

  // Amaiz Cards
  [CompanyPlanKey.AmaizEurCardIssuingFee]: {
    title: CARD_ISSUING_FEE_TITLE,
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Amaiz],
    order: 2,
  },
  [CompanyPlanKey.AmaizEurMonthlyCardIssueLimit]: {
    title: MONTHLY_CARD_ISSUE_LIMIT_TITLE,
    group: CompanyPlanGroup.Cards,
    order: 3,
    providerPaymentServices: [CompanyProviderPaymentService.Amaiz],
  },
  [CompanyPlanKey.AmaizEurActiveCardsLimit]: {
    title: ACTIVE_CARDS_LIMIT_TITLE,
    group: CompanyPlanGroup.Cards,
    order: 4,
    providerPaymentServices: [CompanyProviderPaymentService.Amaiz],
  },
  [CompanyPlanKey.AmaizEurInternationalPaymentFee]: {
    title: INTERNATIONAL_PAYMENT_FEE_TITLE,
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Amaiz],
    order: 5,
  },
  [CompanyPlanKey.AmaizEurExchangeFee]: {
    title: CURRENCY_EXCHANGE_FEE_TITLE,
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Amaiz],
    order: 6,
  },

  // Spenxy Treasury
  [CompanyPlanKey.SpenxyInboundCbFee]: {
    title: INBOUND_CB_FEE_TITLE,
    group: CompanyPlanGroup.Treasury,
    order: 1,
    providerPaymentServices: [CompanyProviderPaymentService.Spenxy],
    incomePaymentMethods: [CompanyProviderIncomePaymentMethod.InboundCrypto],
  },

  // Spenxy Cards
  [CompanyPlanKey.SpenxyCardIssuingFee]: {
    title: CARD_ISSUING_FEE_TITLE,
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Spenxy],
    order: 2,
  },
  [CompanyPlanKey.SpenxyMonthlyCardIssueLimit]: {
    title: MONTHLY_CARD_ISSUE_LIMIT_TITLE,
    group: CompanyPlanGroup.Cards,
    order: 3,
    providerPaymentServices: [CompanyProviderPaymentService.Spenxy],
  },
  [CompanyPlanKey.SpenxyActiveCardsLimit]: {
    title: ACTIVE_CARDS_LIMIT_TITLE,
    group: CompanyPlanGroup.Cards,
    order: 4,
    providerPaymentServices: [CompanyProviderPaymentService.Spenxy],
  },
  [CompanyPlanKey.SpenxyInternationalPaymentFee]: {
    title: INTERNATIONAL_PAYMENT_FEE_TITLE,
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Spenxy],
    order: 5,
  },
  [CompanyPlanKey.SpenxyCurrencyExchangeFee]: {
    title: CURRENCY_EXCHANGE_FEE_TITLE,
    group: CompanyPlanGroup.Cards,
    providerPaymentServices: [CompanyProviderPaymentService.Spenxy],
    order: 6,
  },

  // Common Treasury
  [CompanyPlanKey.CryptoInboundEuroConversionFee]: {
    title: 'Crypto euro conversion fee',
    group: CompanyPlanGroup.Treasury,
    order: 100,
    providerPaymentServices: Object.values(CompanyProviderPaymentService),
    incomePaymentMethods: [CompanyProviderIncomePaymentMethod.InboundCrypto],
    genTooltipText: (value: string) =>
      `Karta charges a ${value} fee for currency conversion when funding in USD, based on the total amount being converted.`,
  },
} as const;

export const CompanyPlanGroupsMap = {
  [CompanyPlanGroup.Treasury]: {
    title: 'Treasury',
    order: 1,
    skeletonRows: 5,
  },
  [CompanyPlanGroup.Cards]: {
    title: 'Virtual cards',
    order: 2,
    skeletonRows: 3,
  },
  [CompanyPlanGroup.Invoices]: {
    title: 'Invoices',
    order: 3,
    skeletonRows: 1,
  },
};
