import { readCompanyPlan } from '@/api';
import type { CompanyPlan } from '@/interfaces';

interface State {
  plan?: CompanyPlan;
}

interface PlanRequestOptions {
  useCache: boolean;
}

export const useCompaniesStore = defineStore('companiesStore', {
  state: (): State => ({
    plan: undefined,
  }),
  actions: {
    async loadPlan(options: PlanRequestOptions = { useCache: false }) {
      const logger = useLogger('load-company-plan');

      if (options.useCache && this.plan) {
        logger.info('get data from cache');
        return;
      }

      try {
        this.plan = await readCompanyPlan();
        logger.info('get data from API');
      } catch (error) {
        logger.error(error);
        throw error;
      }
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCompaniesStore, import.meta.hot));
}
