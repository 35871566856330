import { FilterType } from '@/enums';
import type { CompanyAccountsFilters, FiltersConfig } from '@/interfaces';

export const AccountsFiltersConfig: FiltersConfig<CompanyAccountsFilters> = {
  sourceAccountIds: {
    type: FilterType.Array,
  },
  providerIds: {
    type: FilterType.Array,
  },
} as const;
